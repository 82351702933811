type Event =
  | {
      name: "Ask AI";
      meta: {
        category: "askPodcast" | "askTalk" | "askBook" | "askTejas";
        prompt: string;
      };
    }
  | { name: "AI Response Error"; meta: { prompt: string } }
  | { name: "AI Response Success" }
  | { name: "AI Response Dismiss" }
  | { name: "Learn about costs" }
  | { name: "Exit to tip" }
  | { name: "Click on Pill"; meta: { pill: string } }
  | {
      name: "Exit to company";
      meta: { company: "Spotify" | "Vercel" | "Xata" };
    };

export const trackEvent = (event: Event) => {
  if (!("gtag" in window)) {
    return;
  }

  // @ts-ignore
  window.gtag("event", event.name, event.meta);
};
