import { For, createEffect, createSignal, Show, on } from "solid-js";
import { Button } from "./Button";
import { Input } from "./Input";
import { Pill } from "./Pill";
import { Spinner } from "./Spinner";
import { generateString } from "../util/generateString";
import { trackEvent } from "../util/trackEvent";

type Props = {
  id: string;
  placeholders: string[];
  pills?: string[];
  apiRoute: "askPodcast" | "askTalk" | "askBook" | "askTejas";
  stream?: boolean;
};

type RequestState = "initial" | "failed" | "pending" | "finished";

export function Ask<T>(props: Props) {
  const [query, setQuery] = createSignal("");
  const [results, setResults] = createSignal<T | any>(null);
  const [requestState, setRequestState] = createSignal<RequestState>("initial");
  const [currentPlaceholderIndex, setCurrentPlaceholderIndex] = createSignal(0);
  const [placeholder, setPlaceholder] = createSignal(
    props.placeholders[currentPlaceholderIndex()]
  );
  let eventSource: EventSource | null = null;
  let input: HTMLInputElement | null = null;
  let shouldStopError = false;

  createEffect(
    on(currentPlaceholderIndex, async () => {
      setPlaceholder("");
      for await (const chunk of generateString(
        props.placeholders[currentPlaceholderIndex()]!
      )) {
        setPlaceholder(placeholder() + chunk);
      }
      await new Promise((r) => setTimeout(r, 1000));
      setCurrentPlaceholderIndex(
        currentPlaceholderIndex() === props.placeholders.length - 1
          ? 0
          : currentPlaceholderIndex() + 1
      );
    })
  );

  const ask = async (e?: SubmitEvent) => {
    e?.preventDefault();
    document.getElementById(props.id)?.scrollIntoView({ behavior: "smooth" });
    shouldStopError = false;

    trackEvent({
      name: "Ask AI",
      meta: {
        prompt: query(),
        category: props.apiRoute,
      },
    });

    if (!query()) {
      for await (const chunk of generateString(
        "Maybe you should ask me something first."
      )) {
        if (shouldStopError) {
          break;
        }
        setResults((results() || "") + chunk);
      }
      return;
    }

    if (!props.stream) {
      setRequestState("pending");
      await fetch(`/api/${props.apiRoute}?q=${encodeURIComponent(query())}`)
        .then((r) => r.json())
        .then((d) => setResults(d));
      setRequestState("initial");
    } else {
      setRequestState("pending");
      eventSource = new EventSource(
        `/api/${props.apiRoute}?q=${encodeURIComponent(query())}`
      );
      eventSource.addEventListener("error", async () => {
        eventSource?.close();
        trackEvent({ meta: { prompt: query() }, name: "AI Response Error" });
        for await (const chunk of generateString(
          " ...at this point, either the whole world is using me and I'm under extremely heavy load, or my creator (Tejas) ran out of money to pay for this, or your network is down. In any case, something's wrong. Don't worry—Tejas already knows about this and will fix it soon. If it's super important, please <a target=\"_blank\" href=\"https://twitter.com/intent/post?text=" +
            encodeURIComponent(
              `Ummm @tejaskumar_ I can't use your AI feature but I really want to know: ${query()}`
            ) +
            "\">ask him</a> on social media and he'll get back to you. Thanks for understanding."
        )) {
          if (shouldStopError) {
            break;
          }
          setResults((results() || "") + chunk);
          setRequestState("finished");
        }
      });
      eventSource.addEventListener("message", (e) => {
        setResults((results() || "") + decodeURIComponent(e.data));
      });
      eventSource.addEventListener("done", () => {
        eventSource?.close();
        trackEvent({ name: "AI Response Success" });
        setRequestState("finished");
      });
    }
  };

  createEffect(() => {
    const close = () => {
      setResults(null);
      eventSource?.close();
      shouldStopError = true;
      setRequestState("initial");
    };

    document.addEventListener("click", close);
    document.addEventListener("podcastvideoplaying", close);
    return () => {
      document.removeEventListener("click", close);
      document.removeEventListener("podcastvideoplaying", close);
    };
  });

  return (
    <form class="grid gap-2 relative" id={props.id} onsubmit={ask}>
      <Show when={props.pills}>
        <div class="grid gap-2">
          <div class="flex items-center gap-2 flex-wrap">
            <For each={props.pills}>
              {(pill) => (
                <Pill
                  onclick={() => {
                    trackEvent({ meta: { pill }, name: "Click on Pill" });
                    const value = `Tell me more about ${pill}.`;
                    input!.value = value;
                    setTimeout(() => {
                      setQuery(value);
                      ask();
                    }, 100);
                  }}
                >
                  {pill}
                </Pill>
              )}
            </For>
          </div>
        </div>
      </Show>
      <div class="relative">
        <Input
          ref={input}
          value={query()}
          oninput={(e) => setQuery(e.target.value)}
          placeholder={placeholder()!}
        />
        <Show when={results()}>
          <div
            onclick={(e) => {
              e.stopImmediatePropagation();
            }}
            class="absolute items-center p-4 z-40 top-[100%] md:h-fit md:max-h-[40vh] h-[65vh] overflow-y-auto overflow-x-hidden bottom-0 w-full bg-black bg-opacity-70 backdrop-blur-sm rounded-b-xl"
          >
            <div
              class={requestState() === "pending" ? "ask-result-pending" : ""}
              innerHTML={results()}
            />
          </div>
        </Show>
      </div>
      <div
        data-btn-container
        class="w-full rounded lg:hover:scale-[1.01] transition"
      >
        <Button
          class="w-full p-2 bg-black rounded flex items-center justify-center"
          disabled={requestState() === "pending"}
          type="submit"
        >
          {requestState() === "pending" ? <Spinner /> : "Search with AI"}
        </Button>
      </div>
      <span class="text-xs">
        Running this at scale is{" "}
        <a
          href="https://twitter.com/TejasKumar_/status/1772989809423712501"
          target="_blank"
          onClick={() => {
            trackEvent({ name: "Learn about costs" });
          }}
        >
          pretty expensive
        </a>
        , but will stay free. If you'd like to support this/me,{" "}
        <a
          onClick={() => {
            trackEvent({ name: "Exit to tip" });
          }}
          href="https://ko-fi.com/tejaskumar"
          target="_blank"
        >
          here's how you can
        </a>
        .
      </span>
    </form>
  );
}
